import React, { useEffect, useState } from 'react';
import {Dropdown,  DropdownItem, DropdownToggle, DropdownMenu, Row, Col} from "reactstrap";
import { COLORS } from '../../utils/color';

import './style.css';
import { generate_2d_snapshot_of_composite, generate_3d_snapshot_of_composite, updateWallHatchPatterns } from '../../utils/hatching';

const HatchingDropdown = ({ composites, active_index, on_click, json_item }) => {
    const [dropdownOpen, setDropdownOpen] = useState(false);
    const [composites_copy, set_composites_copy] = useState('');
    // const [rename_enabled, set_rename_enabled] = useState(false);

    const toggle = () => setDropdownOpen(prevState => !prevState);

    const get_hatch_pattern = (pattern) => {
        var temp_canvas = document.createElement('canvas');
        var ctx = temp_canvas.getContext('2d');

        temp_canvas.width = 50;
        temp_canvas.height = 50;

        ctx.fillStyle = '#ffffff'
	    ctx.fillRect(0, 0, temp_canvas.width, temp_canvas.height);
        ctx.fillStyle = pattern;
        ctx.fillRect(0, 0, temp_canvas.width, temp_canvas.height);

        // Convert the canvas to a data URL
        var dataURL = temp_canvas.toDataURL();
        return dataURL;
    }

    const fetch_composite_images = async(copy_composites, set_composites_copy) => {
        if(copy_composites && copy_composites.length){
            let hatch_patterns_map = updateWallHatchPatterns(true)
            for(let i = 0; i < copy_composites.length; i++){
                // copy_composites[i].cut_snapshot = hatch_patterns_map[copy_composites[i].hatchingPattern]
                // copy_composites[i].cut_snapshot = generate_2d_snapshot_of_composite(copy_composites[i])
                // copy_composites[i].surface_snapshot = await generate_3d_snapshot_of_composite(copy_composites[i])
                // console.log('composites copy surface', composite.surface_snapshot)
                for(let j = 0; j < (copy_composites[i].hatchings && copy_composites[i].hatchings.length); j++){
                    copy_composites[i].hatchings[j].cut_snapshot = copy_composites[i].hatchings[j].icon ? copy_composites[i].hatchings[j].icon : hatch_patterns_map[copy_composites[i].hatchings[j].hatchingPattern];
                    // copy_composites[i].hatchings[j].cut_snapshot = hatch_patterns_map[copy_composites[i].hatchings[j].hatchingPattern]
                }
                
            }
        }
        // console.log('composites copy surface', copy_composites)
        set_composites_copy(JSON.parse(JSON.stringify(copy_composites)))
    }

    useEffect(() => {
        // set_composites_copy(composites)
        fetch_composite_images(composites, set_composites_copy)
    }, [composites]);

    // useEffect(() => {
    //     // console.log('composites copy', composites_copy)
    // }, [composites_copy]);

    return (
        // <Row className='class_position_unset' style={{margin:0, height:'100%'}}>
            // <Col className='class_position_unset' style={{padding:0, display:'flex', alignItems:'center'}}>
                <Dropdown className='class_position_unset' isOpen={dropdownOpen} style={{all:'unset', listStyle: 'none'}} toggle={json_item && json_item.disabled?null:toggle}>
                    <DropdownToggle  style={{display:'flex',flexFlow:'row', backgroundColor:'white',cursor:'pointer', color:'black', fontSize:'13px', padding:'4px 10px', border:'1px solid  '+ COLORS.panel_item_borders, alignItems:'center', width: '100%'}}>
                        <div style={{display:'flex',flexFlow:'row',fontSize:'13px',width:'calc( 100% - 16px )', alignItems:'center'}}>
                            {
                                json_item.mixed ? "Mixed"
                                :
                                <React.Fragment>    
                                    {active_index && active_index.cut_snapshot ? (<img src={active_index.cut_snapshot} style={{width:'30px',height:'30px', backgroundColor:'white', marginRight:'5px'}}/>):''}
                                    {/* {composites_copy && active_index != undefined && composites_copy[active_index] && composites_copy[active_index].cut_snapshot ? (<img src={composites_copy[active_index].cut_snapshot} style={{width:'30px',height:'30px', backgroundColor:'beige', marginRight:'5px'}}/>):''} */}
                                    {/* {composites_copy && active_index != undefined && composites_copy[active_index] && composites_copy[active_index].surface_snapshot ?(<img src={composites_copy[active_index].surface_snapshot} style={{width:'30px',height:'30px', backgroundColor:'beige', marginRight:'5px'}}/>):''} */}
                                    <div title={active_index?active_index.name:''} style={{whiteSpace: 'nowrap',overflow: 'hidden',textOverflow: 'ellipsis'}}>{active_index?active_index.name:'Select Composite'}</div>
                                </React.Fragment>
                            }
                        </div>
                        <span><i className='fa fa-caret-down' /></span>
                    </DropdownToggle>
                    <DropdownMenu className="hatching_dropdown_menu" style={{padding:'4px'}}>
                        <DropdownItem header>Hatch Patterns</DropdownItem>
                        {/* <DropdownItem divider/> */}
                        {
                            composites_copy && composites_copy.map((category) => (
                                <React.Fragment>
                                    <DropdownItem divider/>
                                    <DropdownItem header>{category.name}</DropdownItem>
                                    {/* <DropdownItem divider/> */}
                                    {
                                        category.hatchings && category.hatchings.map((composite, idx) => (
                                            <DropdownItem style={{ listStyle: 'none' }} onClick={() => { on_click(composite.hatchingPattern) }} key={idx}>
                                                <div style={{ listStyle: 'none', display: 'flex', flexFlow: 'row', fontSize: '13px' }} title={composite.name ? composite.name : ''}>
                                                    {composite.cut_snapshot ? (<img src={composite.cut_snapshot} style={{ width: '30px', height: '30px', backgroundColor: 'white', marginRight: '5px' }} />) : ''}
                                                    {/* {composite.surface_snapshot?(<img src={composite.surface_snapshot} style={{width:'30px',height:'30px', backgroundColor:'beige', marginRight:'5px'}}/>):''} */}
                                                    <div style={{ paddingTop: '5px', whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis' }}>{composite.name}</div>
                                                </div>
                                            </DropdownItem>
                                        ))
                                    }
                                </React.Fragment>
                            ))
                        }
                    </DropdownMenu>
                </Dropdown>
            // </Col>
        // </Row>
    );
}


export default HatchingDropdown;

