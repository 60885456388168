/* eslint-disable no-unused-expressions */
import React , { useEffect, useState}from 'react';
import { Col, Row, Button, Dropdown, DropdownItem, DropdownToggle, DropdownMenu, Modal, ModalBody, ModalFooter, Card, CardHeader, CardBody, Input, ModalHeader, Navbar, Nav, UncontrolledDropdown } from "reactstrap";
import { COLORS } from '../../utils/color';
import { useAlert } from 'react-alert'
import Card_Component from '../Card_Component';
import is_valid_add_key from '../../utils/is_valid_add_key';
import KeyValuePair from '../KeyValuePair';

import timeSince from '../../utils/time_utils';



import './style.css';

import dummy_tick from '../../utils/dummy_tick';
import Single_Card from '../Single_Card';
import { IModalBody, IModalFooter, IModalHeader } from '../IModal';
import { validate_design_name } from '../../utils/validate_name';
import { Advanced_copy_modal, Share_Branch_Modal_New } from '../CommonModals';
import {useTranslation} from 'react-i18next'
import { Checkbox, Tooltip } from 'antd';

const all_allowed_key = {
    custom_sku_code:{
        id:'custom_sku_code',
        name:'Custom Sku Code',
        type:'text'
    },
	order_id:{
        id:'order_id',
        name:'Order ID',
        type:'text'
    }
}

const styles = {
	// owned_style: {
	// 	display: "unset",
	// 	position: "absolute", 
	// 	backgroundColor:COLORS.white, 
	// 	color:COLORS.black,
	// 	borderRadius:"50%",
	// 	zIndex:250,
	// 	top:'2%', 
	// 	right:'4%',
	// 	fontSize:'12px'
	// },
	text_elipsis : {
		whiteSpace: "nowrap", 
		overflow: "hidden",
		textOverflow: "ellipsis",
		paddingTop: 5
	},
};

const Addn_prop_modal = ({ open, update_design_additional_properties, handle_close, active_design }) => {

	const [ additional_json_item_default, set_additional_json_item_default ] = useState([]);
	const [ additional_json_item_custom, set_additional_json_item_custom ] = useState([]);
	const [addn_prop, set_addn_prop] = useState('');
	const alert = useAlert()

	const onclick_close = () => {
		handle_close('addn_prop')
		set_additional_json_item_default([])
		set_additional_json_item_custom([])
		set_addn_prop('')
	}

	const submit_values = () => {
		if(additional_json_item_default && additional_json_item_default.length>1 && !is_valid_add_key(additional_json_item_default)){
			alert.error("Empty or Duplicate key values not allowed in default properties.")
		}else if(additional_json_item_custom && additional_json_item_custom.length>1 && !is_valid_add_key(additional_json_item_custom)){
			alert.error("Empty or Duplicate key values not allowed in custom properties.")
		}
		else{
			var x_addi_prop = [];
			additional_json_item_default.map(x => {
				if(additional_json_item_default[0].key){
					x_addi_prop.push(x)
				}
			})
			additional_json_item_custom.map(x => {
				if(additional_json_item_custom[0].key){
					x_addi_prop.push(x)
				}
			})
			if(active_design){ 
				window.Module.update_design_additional_properties(active_design.id, JSON.stringify(x_addi_prop))
				alert.success("Updated Additional Properties")
			}
			handle_close('addn_prop')
		}
	}

	useEffect(() => {
		if(active_design && active_design.additional_properties){
			var x_default = [], x_custom=[];
			
			active_design.additional_properties.map(x_item => {
				if(Object.keys(all_allowed_key).includes(x_item.key)){
					x_default.push(x_item)
				}else{
					x_custom.push(x_item)
				}
			})
			
			set_additional_json_item_default(x_default)
			set_additional_json_item_custom(x_custom)
		}
	},[active_design])

	return(
		<Modal className="modal-dialog-centered" size="xl" isOpen={open} toggle={onclick_close}>
            {/* <Card className="bg-secondary shadow border-0">
                <Row>
                    <Col style={{height:'54px', width:'100%', fontSize:'18px', display:'flex', alignItems:'center', justifyContent:'space-between', backgroundColor: COLORS.modal_heading , color:COLORS.white}}>
                        <span>Additional Properties of {active_design?active_design.name:'-'}</span>
                        <span style={{cursor:'pointer'}} onClick={onclick_close}>×</span>
                    </Col>
                </Row>
                <Row>
                    <Col style={{height:'400px', overflow:'auto', width:'100%', backgroundColor: COLORS.white, padding:'20px 24px'}}>
						<Row style={{margin:0,marginTop:'5px', fontSize:'14px'}}>
							<Col xs={1}style={{padding:0, whiteSpace:'nowrap'}}>
								<span style={{fontWeight:'600'}}>Design Name :</span> {active_design?active_design.name:'-'}
							</Col>
						</Row>
						<Row style={{margin:0,marginTop:'5px', fontSize:'14px'}}>
							<Col xs={1}style={{padding:0 , whiteSpace:'nowrap'}}>
							<span style={{fontWeight:'600'}}>Design Sl No. :</span> {active_design?active_design.serial_number:'-'}
							</Col>
						</Row>
						<KeyValuePair item_json={additional_json_item_default} set_item_json={set_additional_json_item_default} mode='default' all_allowed_key={all_allowed_key}/>
						<KeyValuePair item_json={additional_json_item_custom} set_item_json={set_additional_json_item_custom} mode='custom'/>
                    </Col>
                </Row>
				<Row style={{backgroundColor: COLORS.white, padding:'20px 24px', borderTop:'1px solid #f2f2f2'}}>
					<Col style={{textAlign:'right'}}>
						<Button onClick={onclick_close} style={{backgroundColor:COLORS.mainpage_locale, padding:'8px 30px',boxShadow: '0 2px 2px 0 rgba(0, 0, 0, 0.14), 0 3px 1px -2px rgba(0, 0, 0, 0.2), 0 1px 5px 0 rgba(0, 0, 0, 0.12)', border:0, marginRight:'10px'}} type="button">Cancel</Button>
						<Button onClick={submit_values} style={{backgroundColor:COLORS.mainpage_locale, padding:'8px 30px',boxShadow: '0 2px 2px 0 rgba(0, 0, 0, 0.14), 0 3px 1px -2px rgba(0, 0, 0, 0.2), 0 1px 5px 0 rgba(0, 0, 0, 0.12)', border:0}} type="button">Update</Button>
					</Col>  
				</Row>
					
            </Card> */}
			<IModalHeader toggle={onclick_close}>
				Additional Properties of {active_design?active_design.name:'-'}
			</IModalHeader>
			<IModalBody style={{height:'450px', overflow:'auto', alignItems:'center'}}>
			{/* <Navbar>
                    <Nav>
                        <div style={{padding:'4px 16px 4px 0px', borderRight:'1px solid #E8E8E8', fontWeight:template_selection === '' ? '700' : '400', fontSize:'14px' }} onClick={() => set_addn_prop('')}>Default Properties</div>
                        <div style={{padding:'4px 16px 4px 16px', borderRight:'1px solid #E8E8E8', fontWeight:template_selection === 'org' ? '700' : '400', fontSize:'14px'}} onClick={() => set_addn_prop('custom')}>Custom Properties</div>
                    </Nav>
                    <Nav className="justify-content-end">
                        <Input id='template_search' style={{width:'200px', marginRight:'16px', fontSize:'12px', padding:'6px 12px 6px 12px' }} placeholder='Search the Template' type='text' value={search_string} onChange={(e) => set_search_string(e.target.value)}></Input>
                    </Nav> */}
                    {/* <Nav className="justify-content-end">
                        <div></div>
                    </Nav> */}
                {/* </Navbar> */}
				<div style={{display:'flex', height:'400px', overflow:'auto', backgroundColor:'#F5F6F8', alignItems:'center', justifyContent:'space-around'}}>
					<div style={{ overflow:'auto', backgroundColor:'white', height:'80%', width:'45%'}}>
						<KeyValuePair item_json={additional_json_item_default} set_item_json={set_additional_json_item_default} mode='default' all_allowed_key={all_allowed_key}/>
					</div>
					<div style={{overflow:'auto', backgroundColor:'white', height:'80%', width:'45%'}}>
						<KeyValuePair item_json={additional_json_item_custom} set_item_json={set_additional_json_item_custom} mode='custom'/>
					</div>
				</div>
			</IModalBody>

			<IModalFooter style={{justifyContent:'center'}}>
				<Button style={{}} className='primary_button_default' onClick={submit_values}>Update</Button>
			</IModalFooter>
        </Modal>
	)    
}

const Edit_Design_Modal = ({ open, rename_design, handle_close, active_design }) => {
	const alert = useAlert()
	useEffect(() => {
		if(active_design && active_design.name){
			set_design_name(active_design.name);
		}
	},[active_design]);

	const [ design_name, set_design_name ] = useState("");

	const onclick_close = () => {
		set_design_name('')
		handle_close('edit_design')
	}

	const submit_values = () => {
		if(active_design){
			try {
				if(validate_design_name(design_name)){
					rename_design(active_design.id, design_name)
					handle_close('edit_design')
				}else{
					alert.error('Special Characters Not permitted. Please modify the Design Name')
				}
			} catch (ee) {
				alert.error('Request Failed')
			}
		}
	}

	

	useEffect(() => {
		if(active_design && open){
			//console.log("================================================", active_design)
			set_design_name(active_design.name?active_design.name:'')
		}
	},[active_design])

	return(
		<Modal className="modal-dialog-centered" size="md" isOpen={open} toggle={onclick_close}>
            {/* <Card className="bg-secondary shadow border-0">
                <Row>
                    <Col style={{height:'54px', width:'100%', fontSize:'18px', display:'flex', alignItems:'center', justifyContent:'space-between', backgroundColor: COLORS.modal_heading , color:COLORS.white}}>
                        <span>Edit Design</span>
                        <span style={{cursor:'pointer'}} onClick={onclick_close}>×</span>
                    </Col>
                </Row>
                <Row>
                    <Col style={{height:'186px', width:'100%', backgroundColor: COLORS.white, padding:'20px 24px'}}>
                        <Row>
                            <Col style={{width:'100%', display:'flex', alignItems:'center', height:'28px', marginBottom:'10px'}}>
                                <span style={{flex:1, fontSize:'14px'}}>Design Name*</span>
                                <Input style={{flex:3, height:"25px", border:0, borderBottom:'1px solid #ced4da'}} type="text" value={design_name} onChange={(e) => set_design_name(e.target.value)} />
                            </Col>
                        </Row>
                        <Row>
                            <Col style={{textAlign:'center',marginTop:'30px'}}>
                                <Button onClick={submit_values} style={{backgroundColor:COLORS.mainpage_locale, padding:'8px 30px',boxShadow: '0 2px 2px 0 rgba(0, 0, 0, 0.14), 0 3px 1px -2px rgba(0, 0, 0, 0.2), 0 1px 5px 0 rgba(0, 0, 0, 0.12)', border:0}} type="button">Update</Button>
                            </Col>  
                        </Row>
                    </Col>
                </Row>
            </Card> */}
			<IModalHeader toggle={onclick_close}>
				Edit Design
			</IModalHeader>
			<IModalBody>
				<div className='new_project_modal_div'>
                    <span style={{width:'120px' , display:'flex', alignItems:'center'}}>Design Name*</span>
                    <Input style={{width:'280px'}} type='text' placeholder='Design Name' value={design_name} onChange={(e) => set_design_name(e.target.value)}></Input>
                </div>
			</IModalBody>

			<IModalFooter style={{justifyContent:'center'}}> 
                <Button style={{marginRight:'32px'}} className='primary_button_default' onClick={submit_values}>Update</Button>
            </IModalFooter>
        </Modal>
	)    
}

const Copy_Design_Modal = ({ open, handle_close, active_design, active_project_id,set_page_loader, copy_design, user_id }) => {

	const alert = useAlert()
	const [ all_project_list, set_all_project_list] = useState([])
	const [ all_project_list_display, set_all_project_list_display] = useState([])
	const [ search_string, set_search_string] = useState('')
	const [advanced_modal, set_advanced_modal] = useState(false);
	const [advanced_copy_project, set_advanced_copy_project] = useState('')
    const {t} = useTranslation()

    const fetch_projectDetails = () => {
		set_page_loader({
			show: true,
			text: 'Fetching projects...'
		});

		setTimeout(async() => {  
			try{
			var response = await window.Promisify(window.Module.getProjects())
			var response_obj = response && JSON.parse(response)
			console.log("project info", response_obj)
			set_all_project_list_display(response_obj&& response_obj.length?response_obj:'')
			set_all_project_list(response_obj&& response_obj.length?response_obj:'')

			set_page_loader({
				show: false,
				text: 'Please wait'
			});	
			}catch(err){
				if(window['sentry_capture_exception']){
					window['sentry_capture_exception']("",err);
				}
			}
		},0)
	}
	
	useEffect(() => {
		var temp_list=[...all_project_list];
		if(search_string){
			var temp_list = temp_list.filter((project) => project && project.name && project.name.toLowerCase().includes(search_string) )
		}
		set_all_project_list_display(temp_list)
	},[search_string])

	useEffect(() => {
		if(active_design && open){
			fetch_projectDetails()
		}
	},[active_design])

	const onclick_close = () => {
		handle_close('copy_design')
	}

	const onclick_submit = (all_project_list_display_item, advanced_paste_options) => {
		if(active_design && active_design.current_design_branch_id && all_project_list_display_item && all_project_list_display_item.id){
			copy_design(active_design.current_design_branch_id,all_project_list_display_item.id, "Copy of "+active_design.name, advanced_paste_options);
		}
		handle_close('copy_design');
	}

	return(
		<>
		<Advanced_copy_modal
		open={advanced_modal}
		onclick_close={() => {set_advanced_modal(!advanced_modal); set_advanced_copy_project('');}}
		onclick_submit={onclick_submit}
		active_design={active_design}
		copy_design={copy_design}
		active_project_id={active_project_id}
		set_page_loader={set_page_loader}
		user_id={user_id}
		project_item={advanced_copy_project}
		/>
		<Modal className="modal-dialog-centered" size="lg" isOpen={open} toggle={onclick_close}>
			{/* <Card className="bg-secondary shadow border-0">
				<CardHeader style = {{backgroundColor: COLORS.modal_heading , color:COLORS.white}} >
					<div className="text-muted">
						<Row>
							<Col xs={7}style={{color:COLORS.white}}>Copy Design to a Project</Col>
							<Col xs={4}><Input style={{height:'25px', width:'100%' }} placeholder="Search Project" type="text" bsSize='small' value={search_string} onChange={(e) => set_search_string(e.target.value)} /></Col>
							<Col xs={1} style={{textAlign:'right', color:COLORS.white, curser:'pointer'}} onClick={onclick_close}>X</Col>
						</Row>
					</div>
				</CardHeader>
				<CardBody style = {{backgroundColor:COLORS.white, padding:"24px 30px" }} >
					<table style={{height:'100%', width:'100%', fontSize:'13px'}}>
		                <thead>
		                    <tr style={{display:'flex', height:'30px', alignItems:'center', borderBottom:'1px solid #ddd'}}>
		                        <th style={{flex:1}}><span><b>#</b></span><br /></th>
		                        <th style={{flex:5}}><span><b>Project Name</b></span><br /></th>
		                        <th style={{flex:5}}><span><b>Target Project</b></span> <br /></th>
		                    </tr>
		                </thead>
		                <tbody>
		                    {
		                        all_project_list_display && all_project_list_display.length ? all_project_list_display.map((all_project_list_display_item, index) => (
		                            <tr key={index} className='table_custom_strip' style={{display:'flex', borderTop:'1px solid #ddd', height:'50px', alignItems:'center'}}>
		                                <td style={{flex:1}}>{index+1}</td>
		                                <td style={{flex:5}}>{all_project_list_display_item.name?(all_project_list_display_item.id === active_project_id?all_project_list_display_item.name + " (Active)":all_project_list_display_item.name):"-"}</td>
		                                <td style={{flex:5}}><Button onClick={() => onclick_submit(all_project_list_display_item)} className="btn" style={{color: 'black', backgroundColor:COLORS.light_grey, display:'flex', alignItems:'center', justifyContent:"center", height:'25px'}} >Copy Here</Button></td>
		                            </tr>
		                        )):null
		                    }
		                </tbody>
		            </table>
				</CardBody>
			</Card> */}
			<IModalHeader toggle={onclick_close}> Paste Destination</IModalHeader>
			<IModalBody>
				<Navbar>
					<Nav>
						<div style={{fontSize:'16px'/* , fontWeight:'bold' */}}>{t(`Only Owned Projects are displayed`)}</div>
					</Nav>
					<Nav className='justify-content-end'>
						<Input style={{height:'32px', width:'300px', fontSize:'12px'}} placeholder="Search Project" type="text" bsSize='small' value={search_string} onChange={(e) => set_search_string(e.target.value)} />
					</Nav>
				</Navbar>
				<div style={{height:'450px', overflow:'auto', margin:'0px 16px'}}>
				<table style={{fontSize:'12px', width:'100%', position:'relative', backgroundColor:'white'}}>
					<thead style={{position:'sticky', top:'0', backgroundColor:'white'}}>
						<tr style={{display:'flex', height:'30px', alignItems:'center'}}>
							<th style={{flex:5, color:'rgba(167, 168, 178, 1)'}}><span>Destination Projects</span></th>
							<th style={{flex:5, color:'rgba(167, 168, 178, 1)', textAlign:'center'}}><span>Action</span></th>
							<th style={{flex: 2}}><span></span></th>
						</tr>
					</thead>
					<tbody style={{}}>
						{
							all_project_list_display && all_project_list_display.length ? all_project_list_display.filter(all_project_list_display_item => all_project_list_display_item.user_id == user_id).map((all_project_list_display_item, index) => (
								<tr key={index} className='table_custom_strip show_on_hover' style={{display:'flex', borderTop:'1px solid #ddd', height:'50px', alignItems:'center'}}>
									<td style={{flex:5}}>{all_project_list_display_item.name?(all_project_list_display_item.id === active_project_id?all_project_list_display_item.name + " (Active)":all_project_list_display_item.name):"-"}</td>
									<td style={{flex:5, color:'rgba(0, 120, 255, 1)', textAlign:'center', cursor:'pointer'}} onClick={() => {all_project_list_display_item.user_id == user_id ?onclick_submit(all_project_list_display_item):alert.error('Cannot Copy design to Non owned Project')}} className='hide'>Paste Here</td>
									<td style={{flex:2, color:'rgba(0, 120, 255, 1)', textAlign:'center', cursor:'pointer'}} className='hide'>
										<UncontrolledDropdown className='class_position_unset'>
										<DropdownToggle className='hide' outline style={{ outline: 'unset', padding: 'unset', border: 'unset', boxShadow: 'unset', display: 'flex', alignItems: 'center', background: 'transparent', padding: '2px 6px 2px 2px', maxHeight: '24px', aspectRatio: '1'}} onClick={e => {e.stopPropagation();}}>
											<img src='/resources/icons/options.svg'></img>
										</DropdownToggle>
										<DropdownMenu style={{position: 'absolute', paddingTop: '6px', paddingBottom: '6px'}}>
											<DropdownItem onClick={(all_project_list_display_item.user_id == user_id) ? () => {set_advanced_modal(true); set_advanced_copy_project(all_project_list_display_item);} : () => {alert.error('Cannot Copy design to Non owned Project')}}>Advanced paste option</DropdownItem>
										</DropdownMenu>
									</UncontrolledDropdown>
									</td>
									{/* <td style={{flex:5}}><Button onClick={() => onclick_submit(all_project_list_display_item)} className="btn" style={{color: 'black', backgroundColor:COLORS.light_grey, display:'flex', alignItems:'center', justifyContent:"center", height:'25px'}} >Copy Here</Button></td> */}
								</tr>
							)):null
						}
					</tbody>
				</table>
				</div>
			</IModalBody>

		</Modal>
		</>
	)    
}

const Card_Layout = ({ user_id, fetch_project_all_designs, open_new_design_modal, list, no_of_cards, copy_design, update_design_additional_properties,rename_design, delete_design, submit_for_delete_confirm, page_name, image_onclick_fn, is_template, active_project_id, set_page_loader, project_designer_id}) => {

	const alert = useAlert()
	const [ show_edit_design_modal, set_show_edit_design_modal] = useState(false);
	const [ show_copy_design_modal, set_show_copy_design_modal] = useState(false);
	const [ show_addn_prop_modal, set_show_addn_prop_modal] = useState(false);
	const [ show_share_design, set_show_share_design] = useState(false);
	const [ active_item, set_active_item] = useState('');
	const [is_lock_warning_open, set_is_lock_warning_open] = useState(false);

	const onclick_open_modal = (active_item, modal_type) => {
		set_active_item(active_item?active_item:'')
		if(modal_type==='edit_design'){
			set_show_edit_design_modal(true)
		}
		if(modal_type==='copy_design'){
			set_show_copy_design_modal(true)
		}
		if(modal_type==='addn_prop'){
			set_show_addn_prop_modal(true)
		}
		if(modal_type==='lock_design'){
			set_is_lock_warning_open(true)
		}
		if(modal_type==='share_design'){
			set_show_share_design(true)
		}
	} 

	const onclick_close_modal = (modal_type) => {
		set_active_item('')
		if(modal_type==='edit_design'){
			set_show_edit_design_modal(false)
		}
		if(modal_type==='copy_design'){
			set_show_copy_design_modal(false)
		}
		if(modal_type==='addn_prop'){
			set_show_addn_prop_modal(false)
		}
		if(modal_type==='share_design'){
			set_show_share_design(false)
		}
	}
	

	const lock_design = async (item) => {
		set_active_item(active_item?active_item:'')
		try{
			console.log(item)
			var resp = await window.Promisify(window.Module.lock_design(item.id, "false"));
			alert.success("Design locked successfully");
			fetch_project_all_designs();
			onclick_close_lock_warning_modal()
		}catch(err){
			alert.error("Unable to lock design, Please try again later");
			console.error(err);
		}
	}

	const onclick_close_lock_warning_modal = () => {
		set_active_item('')
		set_is_lock_warning_open(false)
	}

	const Lock_Warning_Modal = () => {
		return(
			<Modal className='modal-dialog-centered' isOpen={is_lock_warning_open} toggle={onclick_close_lock_warning_modal} size='md'>
				{/* <ModalHeader style={{ backgroundColor:'rgba(34, 61, 95, 1)', color:'white'}} toggle={onclick_close}>New Project</ModalHeader> */}
				<IModalBody style={{fontSize:'14px', backgroundColor: 'white', padding: '36px 16px', borderRadius:'4px'}}>
					<div style={{textAlign: 'center', marginBottom: '24px'}}>
						Once the design is locked, you will not be able to edit it until an admin unlocks it. Are you sure you want to lock the design?
					</div>
					<div className='flex_center'> 
						<Button onClick={() => lock_design(active_item)} className='primary_button_default mr-2'>Submit</Button>
						<Button onClick={onclick_close_lock_warning_modal} className='secondary_button_default'>Cancel</Button>
					</div>
				</IModalBody>
			</Modal>
		)
	}

	const request_unlock_design = async (item) => {
		set_active_item(active_item?active_item:'')
		try{
			var resp = await window.Promisify(window.Module.request_unlock_design(item.id));
			alert.success("Design unlock request sent successfully");
		}catch(err){
			alert.error("Unable to request for unlock, Please try again later");
			console.error(err);
		}
	}

	const mark_branch_as_template = async (item) => {
		try{
			var resp = await window.Promisify(window.Module.mark_branch_as_template(item.current_design_branch_id));
			console.log(resp)
			if(resp == "UNAUTHORIZED_ACCESS"){
				alert.error("Insufficient permissions to mark as a template. Project manager role is required")
			}else{
			alert.success("Design is now a template design");
			fetch_project_all_designs();
			}
		}catch(err){
			alert.error("Unable to mark design as a template. Please try again later");
			console.error(err);
		}
	}

	const unmark_branch_as_template = async (item) => {
		try{
			var resp = await window.Promisify(window.Module.unmark_branch_as_template(item.current_design_branch_id));
			console.log(resp)
			if(resp == "UNAUTHORIZED_ACCESS"){
				alert.error("Insufficient permissions to unmark as a template. Project manager role is required")
			}else{
			alert.success("Design is no longer a template design");
			fetch_project_all_designs();
			}
		}catch(err){
			alert.error("Unable to mark design as not a template. Please try again later");
			console.error(err);
		}
	}

	useEffect(() => {
		if(list.length){
			//console.log('recentd => ', list)
		}
	},[list])

	const Design_Dropdown = ({ active_item, dropdown_open, set_dropdown_open }) => {
		try {
			const [store_type, set_store_type] = useState("");
			const [admin_account, set_admin_account] = useState(false);
			useEffect(() => {
				if(dropdown_open){
					set_store_type(window.Module.get_active_user().store_type)
					set_admin_account(window.Module.get_active_user().admin_account)
				}
			},[dropdown_open])

			return (
				<Dropdown isOpen={dropdown_open}  toggle={() => set_dropdown_open(!dropdown_open)}>
					<DropdownToggle /*className="btn-circle mr-0"*/color='grey' style={{backgroundColor:'white'}} ><span className="fa fa-bars" /></DropdownToggle>
					<DropdownMenu  container="body">
						<DropdownItem className={user_id!=active_item.user_id?'disabled_dropdown_item':''} title={user_id!=active_item.user_id?"Only the original author can modify the design":""} onClick={() => user_id==active_item.user_id?onclick_open_modal(active_item,'edit_design'):''}>Edit Name</DropdownItem>
						<DropdownItem onClick={() => onclick_open_modal(active_item,'copy_design')}>Copy</DropdownItem>
						<DropdownItem onClick={() => onclick_open_modal(active_item,'share_design')}>Share</DropdownItem>
						{store_type!="professional"?(active_item && !active_item.locked?(
							<DropdownItem className={user_id!=active_item.user_id?'disabled_dropdown_item':''}  title={user_id!=active_item.user_id?"Only the original author can modify the design":""} onClick={() => {user_id==active_item.user_id?onclick_open_modal(active_item, 'lock_design'):''}} >
									{'Lock'}
							</DropdownItem>
						):(
							<DropdownItem className={user_id!=active_item.user_id?'disabled_dropdown_item':''} title={user_id!=active_item.user_id?"Only the original author can modify the design":""} onClick={() => {user_id==active_item.user_id?request_unlock_design(active_item):''}} >
									{'Request Unlock'}
							</DropdownItem>
						)):''}
						{(store_type!="professional" && active_item.current_branch_details && active_item.current_branch_details.org_template == 0)?(<DropdownItem className={(!admin_account)?'disabled_dropdown_item':''} title={(!admin_account)?"Only the right admin can mark the design as a template":""} onClick ={()=> (admin_account)?mark_branch_as_template(active_item):''} >Mark as template</DropdownItem>):''}
						{(store_type!="professional" && active_item.current_branch_details && active_item.current_branch_details.org_template == 1)?(<DropdownItem className={(!admin_account)?'disabled_dropdown_item':''} title={(!admin_account)?"Only the right admin can mark the design as a template":""} onClick ={()=> (admin_account)?unmark_branch_as_template(active_item):''} >Unmark as template</DropdownItem>):''}
						<DropdownItem className={user_id!=active_item.user_id?'disabled_dropdown_item':''} title={user_id!=active_item.user_id?"Only the original author can modify the design":""} onClick ={()=> user_id==active_item.user_id?onclick_open_modal(active_item,'addn_prop'):''} >Additional Properties</DropdownItem>
						{(active_item && !active_item.locked)?( 
							<DropdownItem className={user_id!=active_item.user_id?'disabled_dropdown_item':''} title={user_id!=active_item.user_id?"Only the original author can modify the design":""} onClick ={() => user_id==active_item.user_id?(submit_for_delete_confirm(()=>delete_design(active_item.id), ()=>{}, 'This will delete the design permanently. Are you sure?')):''} ><span style={{color:'red'}}>Delete</span></DropdownItem>
						):''}
					</DropdownMenu>
				</Dropdown>     
			)
		}catch(err) {

		}
	}


	const generate_recent_designs_timestamp = (time_string) => {
		var final_string = "";
		var date = new Date(time_string);

		var split = date.toDateString().split(" ")
		final_string +=  (split[2] + " " + split[1] + ", " + split[3]);
		final_string += ("(" + timeSince(time_string) + ")");

		return final_string
	}


	return (
		<div style={{height:'100%',width:'100%'}}>
			<Lock_Warning_Modal></Lock_Warning_Modal>
			<Edit_Design_Modal open={show_edit_design_modal} rename_design={rename_design} handle_close={onclick_close_modal} active_design={active_item} />
			<Copy_Design_Modal open={show_copy_design_modal} copy_design={copy_design} active_project_id={active_project_id} handle_close={onclick_close_modal} active_design={active_item} set_page_loader={set_page_loader} user_id={user_id}/>
			<Addn_prop_modal open={show_addn_prop_modal} update_design_additional_properties={update_design_additional_properties} active_project_id={active_project_id} handle_close={onclick_close_modal} active_design={active_item} set_page_loader={set_page_loader} />
			<Share_Branch_Modal_New open={show_share_design} handle_close={() => onclick_close_modal('share_design')} active_design={active_item} set_page_loader={set_page_loader} />
			<Row style={{margin:'0px',width:'100%', overflow:'auto', padding:page_name==='main'? '0px' : page_name==='designs' ? '30px 15px' : '30px 0px'}} className='invisiblescroll'>
				{
					page_name==='designs'?
					(
						<Col xs={12} sm={6} md={4} lg={3} style={{ width:'100%',marginBottom:'30px'}}> 
							{/* <div style={{ position:'relative', paddingBottom:'56.25%', margin:'15px', marginTop:'20px'}}  >  
								<div style={{ position:'absolute', top:'0', left:'0', bottom:'0', right:'0'}}  >  
									<div style={{  height:"100%", width:'100%', cursor:'pointer'}}  >  
										<img onClick={open_new_design_modal} style={{  width:'100%', height:"100%"}} src="/resources/images/new_design.png"/>
									</div>
								</div>
							</div> */}
							<div style={{ width:'100%', height:'100%', cursor:'pointer', boxShadow:'0px 4px 16px rgba(0, 0, 0, 0.1)'}} onClick={()=>{project_designer_id!=user_id?alert.error('Cannot create designs in Non owned Projects'):open_new_design_modal()}} className='scale_105'>
                                <div>
                                    <img style={{width:'100%', aspectRatio:'1.77', borderTopRightRadius:'4px', borderTopLeftRadius:'4px'}} title={project_designer_id==user_id?"Create New Design":"Cannot create designs in Non owned Projects"} src="/resources/images/new_design.png"/>
                                </div>
                                {/* <div style={{...styles.text_elipsis, color:COLORS.black, padding:'6px 12px', backgroundColor:'white', borderBottomRightRadius:'4px', borderBottomLeftRadius:'4px'}} title={item_project_name && item_name?item_project_name + " | " + item_name :''}>{item_project_name?item_project_name:''} | {item_name?item_name:''}</div> */}

                            </div>
						</Col>
	                ):''
	            }
				{
					list && list.length ? list.map( (list_item, idx) => (	
					<Col xs={12} sm={6} md={4} lg={no_of_cards?no_of_cards:4} key={idx} style={{  width:'auto', marginBottom:'30px'}}>
						<Single_Card
							list_item={list_item?list_item:''}
							item_id={list_item && list_item.id?list_item.id:''}
							item_name={list_item && list_item.name ? list_item.name:''}
							item_img_src={list_item.display_pic && list_item.display_pic.image ? list_item.display_pic.image :''}
							item_project_name={list_item.Project && list_item.Project.name ?list_item.Project.name:''} 
							item_updated_at_locale={list_item.last_activity_at?generate_recent_designs_timestamp(list_item.last_activity_at):''}
							page_name={page_name}
							Header_Element={Design_Dropdown}
							image_onclick_fn={image_onclick_fn}
							is_template={is_template}
							active_project_id={active_project_id}
							set_page_loader={set_page_loader} 
						/>
					</Col>  
				)): ""
			}
			</Row>
		</div>
	)
}

					
export default Card_Layout;
