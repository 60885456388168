import debug_log from "./debug_log";

export const send_analytics = (analytics_json = {}, require_branch_id = false) => {
    try {
        let active_user_details = window.Module.get_active_user()
        let analytics_json_complete = { ...analytics_json, "company_id": active_user_details.store_id, "user_id": active_user_details.designer_id }
        if (require_branch_id) {
            var split = window.location.pathname.split('/');
            var branch_id = split[split.length - 1];
            analytics_json_complete.design_branch_id = branch_id
        }
        analytics_json_complete.interface = window.Module.get_interface_mode();
        analytics_json_complete.perspective = window.Module.get_perspective();
        // will change it to component_editor later or give a function from core to identify directly.
        var is_seller_specific = window.location.href.includes('/seller/');
        analytics_json_complete.portal = "design"
        if(is_seller_specific){
            analytics_json_complete.portal = "seller"
            analytics_json_complete.editing_user_component = false
            if(window.Module.is_seller_component_a_user_element()){
                analytics_json_complete.editing_user_component = true
            }
        }
        try {
            if (window.Module.API.application_env == "production"){
                if(window.analytics){
                    window.analytics.track('auto', analytics_json_complete);
                    debug_log("analytics - ", analytics_json_complete)
                }
            }else{
                debug_log("analytics - ", analytics_json_complete)
                window.Module.Testing_API.add_analytics_data(JSON.stringify(analytics_json_complete))
            }
        } catch (err) {
            console.error("analytics failed with error ", err);
        }
    } catch (err) {
        console.error('Error in creating analytics data ', err)
    }
}