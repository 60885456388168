const get_image_url_from_FS = (preview) => {
    if(preview && window.FS.root.contents[preview]){
        const imageBlob = new Blob([window.FS.root.contents[preview].contents]);

        // Create a URL for the Blob
        let url = (URL.createObjectURL(imageBlob));
        return url
    }
    return '';
}

export default get_image_url_from_FS;